export function removeAllErrors(form, fields) {
    const errorMessages = form.querySelectorAll('.error-message');

    errorMessages.forEach((errorMessage) => {
        errorMessage.remove();
    });

    fields.forEach((field) => {
        field.classList.remove('--error');
    });
}
