import { initScrollLocker } from '../../../../Common/Resources/src_front/js/components/initScrollLocker';
import { initStopAnimateOnResize } from '../../../../Common/Resources/src_front/js/components/initStopAnimateOnResize';
import { initHashEmail } from '../../../../Common/Resources/src_front/js/components/initHashEmail';
import { initSubscribeForm } from './dark-theme/components/forms/initSubscribeForm';
import { initScrollToAnchor } from '../../../../Common/Resources/src_front/js/components/initScrollToAnchor';

document.addEventListener('DOMContentLoaded', handleOnLoadPage);

function handleOnLoadPage() {
    initHashEmail();
    initStopAnimateOnResize();
    initScrollLocker();
    initSubscribeForm();
    initScrollToAnchor();
}
