import HystModal from 'hystmodal';
import { isValidMail } from '../../../../../../../Common/Resources/src_front/js/helper-functions/isValidMail';
import { fetchForm } from './utils/fetchForm';
import { displayError } from './utils/displayError';
import { removeError } from './utils/removeError';
import { removeAllErrors } from './utils/removeAllErrors';

export const initSubscribeForm = () => {
    // VARS
    const forms = document.querySelectorAll('[data-subscribe-form]');
    if (!forms.length) return;
    let isValidForm = true;
    const formId = '35';

    // INITS
    const modalResponseMessage = new HystModal({
        catchFocus: false,
        waitTransitions: true,
        closeOnEsc: true,
    });

    // LISTENERS
    forms.forEach((form) => {
        form.addEventListener('submit', handleOnSubmit);
        form.addEventListener('focus', handleOnFocus, true);
    });

    // HANDLERS
    async function handleOnSubmit(event) {
        event.preventDefault();
        const form = this;
        removeAllErrors(form, [this]);

        validateEmail(form);

        if (isValidForm) {
            const action = `https://affspace.net/blog/wp-json/contact-form-7/v1/contact-forms/${formId}/feedback`;
            const formData = getFormData(form);

            await fetchForm(form, action, formData, handleOnSuccessFetch, handleOnErrorFetch);
        } else {
            isValidForm = true;
        }
    }

    function handleOnFocus(event) {
        const target = event.target;
        const fields = ['email'];

        if (fields.includes(target.dataset.validateField)) {
            removeError(target);
        }
    }

    // FUNCTIONS
    function validateEmail(form) {
        const email = form.querySelector('[data-validate-field="email"]');
        const value = email.value.trim();

        if (!value) {
            displayError(email, email.dataset.errorRequired);
            email.classList.add('--error');
            isValidForm = false;
        } else if (!isValidMail(value)) {
            displayError(email, email.dataset.errorInvalid);
            email.classList.add('--error');
            isValidForm = false;
        }
    }

    function handleOnSuccessFetch(form, data) {
        const email = form.querySelector('[data-validate-field="email"]');
        const url = form.getAttribute('data-subscribe-form');
        if (data.status === 'mail_sent') {
            window.location.href = `${window.location.origin}${url}?email=${email.value.trim()}`;
        } else {
            modalResponseMessage.open('[data-contact-form="error"]');
        }
    }

    function handleOnErrorFetch(data) {
        modalResponseMessage.open('[data-contact-form="error"]');
    }

    function getFormData(form) {
        const formData = new FormData();
        const email = form.querySelector('[data-validate-field="email"]');

        formData.set('_wpcf7', formId);
        formData.set('_wpcf7_version', '5.9');
        formData.set('_wpcf7_locale', 'uk');
        formData.set('_wpcf7_locale', 'uk');
        formData.set('_wpcf7_container_post', '0');
        formData.set('_wpcf7_unit_tag', `wpcf7-f${formId}-o1`);
        formData.set('_wpcf7_posted_data_hash', '');
        formData.set('email', email.value.trim());

        return formData;
    }
};
