export async function fetchForm(form, url, data, callbackSuccess = () => {}, callbackError = () => {}) {
    form.classList.add('--disabled');

    try {
        const response = await fetch(url, {
            method: 'POST',
            body: data,
        });

        if (response.ok) {
            const data = await response.json();
            callbackSuccess(form, data);
        } else {
            console.log(response.error());
            callbackError();
        }

        form.classList.remove('--disabled');
    } catch (error) {
        console.log(error);
        callbackError();
        form.classList.remove('--disabled');
    }
}
